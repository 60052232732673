import { createApp, defineAsyncComponent } from 'vue';
import store from 'shared/store';
import HeroBannerBlock from './heroBannerBlock.vue';
import './c-hero-banner-block.scss';

const heroBannerBlock = document.getElementById('hero-banner-block');

if (heroBannerBlock) {
	const app = createApp({
		name: 'HeroBannerBlockApp',
	});
	app.use(store);
	app.component('hero-banner-block', HeroBannerBlock);
	app.component('send-campaign-id-cta-button', defineAsyncComponent(
		() => import('shared/components/actionButtonWithCTA/SendCampaignIdCtaButton.vue')
	));
	app.mount(heroBannerBlock);
}