<template>
	<div v-if="embedUrl"
		 class="c-hero-banner-block__media c-hero-banner-block__media--video">
		<iframe
				ref="heroVideo"
				class="c-hero-banner-block__iframe"
				title="video"
				:data-src="embedUrl"
				:allow="[ isVimeo ? 'autoplay' : 'autoplay; accelerometer; encrypted-media; gyroscope' ]"
				autoplay
				:src="embedUrl"
				>
		</iframe>
	    <button v-if="!videoIsPlaying"
				type="button"
				class="c-hero-banner-block__button --play"
				ref="playButton"
				@click="playVideo">
		</button>

		<button v-else
				type="button"
				class="c-hero-banner-block__button --pause"
				@click="pauseVideo()">
		</button>
	</div>
</template>

<script>
	import { youtubeVideoUrl } from 'shared/services/urlTypeService';
	import { track_content_engagement } from 'shared/services/tracking/contentTrackingService';

	export default {
		name: 'video-element',
		props: {
			videoUrl: {
				type: String,
				required: false,
			},
			headline: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				videoIsPlaying: false,
			}
		},
		computed: {
			isVimeo() {
				return this.videoUrl.indexOf('vimeo.com') != -1;
			},
			vimeoVideoParams() {
				let params = '?autoplay=1&muted=1&controls=0&api=1&title=0&byline=0&portrait=0&sidedock=0&playsinline=1&autopip=none&pip=none&byline=0&responsive=1';
				if (!window.marketingPermissions) {
					params += '&dnt=true';
				}
				return params;
			},
			embedUrl() {
				const
					embedTemplate = this.isVimeo ? 'https://player.vimeo.com/video/' : youtubeVideoUrl(),
					embedParams = this.isVimeo ? this.vimeoVideoParams : '?fs=0&modestbranding=0&cc_load_policy=0&controls=0&iv_load_policy=3&enablejsapi=1&version=3&playsinline=1&autoplay=1&mute=1';
				if (this.embedVideoId)
					return embedTemplate + this.embedVideoId + embedParams;
				else
					return null;
			},
			embedVideoId() {
				if (this.videoUrl) {
					if (!this.isVimeo) {
						const m = /watch\?v=([^\&$]*)/.exec(this.videoUrl);
						if (m) {
							return m[1];
						}
					}
					else {
						const m = this.videoUrl.split('/');
						if (m) {
							return m[m.length - 1];
						}
					}
				}
			},
		},
		watch: {
			videoIsPlaying(newValue, oldValue) {
				if (oldValue === false && newValue === true) {
					track_content_engagement('video', 'start', this.headline || 'no_data', this.videoUrl);
				}
			}
		},
		mounted() {
			let tag = document.createElement('script');
			tag.id = this.isVimeo ? 'iframe-vimeo' : 'iframe-youtube';
			tag.src = this.isVimeo ? 'https://player.vimeo.com/api/player.js' : 'https://www.youtube.com/iframe_api';
			tag.type = 'text/javascript';
			tag.async = true;
			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

			tag.addEventListener('load', () => {
				this.$nextTick(() => {
					const player = this.$refs.heroVideo;
					if (!this.isVimeo) {
						window.onYouTubePlayerAPIReady = () => {
							this.videoPlayer = new YT.Player(player, {
								events: {
									'onReady': () => {
										this.videoIsPlaying = true;
									},
								}
							});
						}
					}
					else {
						this.videoPlayer = new Vimeo.Player(player);
						this.videoPlayer.on('loaded', () => {
							this.videoIsPlaying = true;
						});
						this.videoPlayer.on('ended', () => {
							this.videoIsPlaying = false;
							this.videoPlayer.setCurrentTime(0);
						});
					}
				});
			});
		},
		methods: {
			playVideo() {
				this.videoIsPlaying = !this.videoIsPlaying;
				if (!this.isVimeo) {
					this.videoPlayer.playVideo();
				}
				else {
					this.videoPlayer.play();
				}
				track_content_engagement('video', 'start', this.headline || 'no_data', this.videoUrl);
			},
			pauseVideo() {
				this.videoIsPlaying = !this.videoIsPlaying;
				if (!this.isVimeo) {
					this.videoPlayer.pauseVideo();
				}
				else {
					this.videoPlayer.pause();
				}
			},
		},
	}
</script>
